@font-face {
  font-family: 'icomoon';
  src:  url('..fonts/icomoon.eot?g6pkjx');
  src:  url('..fonts/icomoon.eot?g6pkjx#iefix') format('embedded-opentype'),
    url('..fonts/icomoon.ttf?g6pkjx') format('truetype'),
    url('..fonts/icomoon.woff?g6pkjx') format('woff'),
    url('..fonts/icomoon.svg?g6pkjx#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-alarm-clock:before {
  content: "\e900";
}
.icon-calendar-with-a-clock-time-tools:before {
  content: "\e901";
}
.icon-conversation:before {
  content: "\e902";
}
.icon-customer-help-clerk:before {
  content: "\e903";
}
.icon-event-date-and-time-symbol:before {
  content: "\e904";
}
.icon-face-of-a-woman-outline:before {
  content: "\e905";
}
.icon-female-graduate-student:before {
  content: "\e906";
}
.icon-film-roll:before {
  content: "\e907";
}
.icon-hair-scissors-and-comb:before {
  content: "\e908";
  color: #7e293d;
}
.icon-lipstick:before {
  content: "\e909";
}
.icon-mirror:before {
  content: "\e90a";
}
.icon-mother-walking-with-her-son-on-a-stroller:before {
  content: "\e90b";
}
.icon-paint-class:before {
  content: "\e90c";
}
.icon-star:before {
  content: "\e90d";
}
